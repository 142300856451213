const Languages = [
  ['af', 'Afrikaans'],
  ['sq', 'Shqip'],
  ['am', 'አማርኛ'],
  ['ar', 'العربية'],
  ['hy', 'Հայերեն'],
  ['az', 'Azərbaycan dili'],
  ['eu', 'Euskara'],
  ['be', 'Беларуская'],
  ['bn', 'বাংলা'],
  ['bs', 'Bosanski'],
  ['bg', 'Български'],
  ['ca', 'Català'],
  ['ceb', 'Cebuano'],
  ['zh-CN', '简体中文'],
  ['zh', '中文'],
  ['zh-TW', '繁體中文'],
  ['co', 'Corsu'],
  ['hr', 'Hrvatski'],
  ['cs', 'Čeština'],
  ['da', 'Dansk'],
  ['nl', 'Nederlands'],
  ['en', 'English'],
  ['eo', 'Esperanto'],
  ['et', 'Eesti'],
  ['fi', 'Suomi'],
  ['fr', 'Français'],
  ['fy', 'Frysk'],
  ['gl', 'Galego'],
  ['ka', 'ქართული'],
  ['de', 'Deutsch'],
  ['el', 'Ελληνικά'],
  ['gu', 'ગુજરાતી'],
  ['ht', 'Kreyòl ayisyen'],
  ['ha', 'Hausa'],
  ['haw', 'ʻŌlelo Hawaiʻi'],
  ['he', 'עברית'],
  ['iw', 'עברית'],
  ['hi', 'हिन्दी'],
  ['hmn', 'Hmoob'],
  ['hu', 'Magyar'],
  ['is', 'Íslenska'],
  ['ig', 'Igbo'],
  ['id', 'Indonesia'],
  ['ga', 'Gaeilge'],
  ['it', 'Italiano'],
  ['ja', '日本語'],
  ['jv', 'Basa Jawa'],
  ['kn', 'ಕನ್ನಡ'],
  ['kk', 'Қазақ тілі'],
  ['km', 'ភាសាខ្មែរ'],
  ['rw', 'Kinyarwanda'],
  ['ko', '한국어'],
  ['ku', 'Kurdî'],
  ['ky', 'Кыргызча'],
  ['lo', 'ລາວ'],
  ['la', 'Latina'],
  ['lv', 'Latviešu'],
  ['lt', 'Lietuvių'],
  ['lb', 'Lëtzebuergesch'],
  ['mk', 'Македонски'],
  ['mg', 'Malagasy'],
  ['ms', 'Bahasa Melayu'],
  ['ml', 'മലയാളം'],
  ['mt', 'Malti'],
  ['mi', 'Māori'],
  ['mr', 'मराठी'],
  ['mn', 'Монгол'],
  ['my', 'ဗမာ'],
  ['ne', 'नेपाली'],
  ['no', 'Norsk'],
  ['ny', 'Chichewa'],
  ['or', 'ଓଡ଼ିଆ'],
  ['ps', 'پښتو'],
  ['fa', 'فارسی'],
  ['pl', 'Polski'],
  ['pt', 'Português'],
  ['pa', 'ਪੰਜਾਬੀ'],
  ['ro', 'Română'],
  ['ru', 'Русский'],
  ['sm', 'Gagana fa\'a Samoa'],
  ['gd', 'Gàidhlig'],
  ['sr', 'Српски'],
  ['st', 'Sesotho'],
  ['sn', 'Shona'],
  ['sd', 'سنڌي'],
  ['si', 'සිංහල'],
  ['sk', 'Slovenčina'],
  ['sl', 'Slovenščina'],
  ['so', 'Soomaali'],
  ['es', 'Español'],
  ['su', 'Basa Sunda'],
  ['sw', 'Kiswahili'],
  ['sv', 'Svenska'],
  ['tl', 'Tagalog'],
  ['tg', 'Тоҷикӣ'],
  ['ta', 'தமிழ்'],
  ['tt', 'Татар теле'],
  ['te', 'తెలుగు'],
  ['th', 'ไทย'],
  ['tr', 'Türkçe'],
  ['tk', 'Türkmen'],
  ['uk', 'Українська'],
  ['ur', 'اردو'],
  ['ug', 'ئۇيغۇرچە'],
  ['uz', 'O‘zbek'],
  ['vi', 'Tiếng Việt'],
  ['cy', 'Cymraeg'],
  ['xh', 'isiXhosa'],
  ['yi', 'ייִדיש'],
  ['yo', 'Yorùbá'],
  ['zu', 'isiZulu']
] as const;

export default Languages;
